<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();
const isGuest = useIsGuest();
const { open } = useNlcModals();
const { isCanPlay } = useBingoData({ immediate: false });
const loginGuard = useLoginGuard();

defineProps<{
	title?: string;
	type?: Promotion["type"];
	image: string;
}>();

const buttonName = computed(() => {
	if (isGuest.value) {
		return t("Register & play");
	}
	if (isCanPlay.value) {
		return t("Play bingo");
	}
	return t("Keep going");
});

const handleClickInfo = () => {
	loginGuard({
		success: () => {
			open("LazyOModalBingoHowItWorks");
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
};

const handleClick = () => {
	if (isCanPlay.value) {
		open("LazyOModalBingoPlay");
		return;
	}
	navigateTo("/issues/popular-games/");
};
</script>

<template>
	<MPromotionWrapper
		:type="type"
		:title="title"
		:image="image"
		buttonInfo
		:buttonName="buttonName"
		@click-info="handleClickInfo"
		@click-card="handleClick"
	>
		<AText :size="16" class="subtitle text-tertiary-90" :modifiers="['linght']" as="div">
			{{ t("Play on to unlock BINGO GAME") }}
		</AText>
		<MBingoProgress v-if="!isGuest" />
		<AText v-else :modifiers="['bold', 'uppercase']" as="div">
			{{ t("Earn Bingo balls playing slots") }}
		</AText>
	</MPromotionWrapper>
</template>

<style scoped lang="scss">
.subtitle {
	margin-bottom: 8px;
}

.progress-wrapper:deep(.text) {
	font-size: 14px;
	font-weight: 500;

	& > span {
		font-weight: 700;
	}
}
</style>
